import { defineComponent, h, watch, ref, computed } from "vue";
import type { PropType, Slot, VNode } from "vue";
import { VTextField } from "vuetify/components";
import parsePhoneNumber from "libphonenumber-js";
import type { CountryCode, NumberFormat, PhoneNumber } from "libphonenumber-js";
import { omit, get } from "lodash";

const formats = ["NATIONAL", "INTERNATIONAL", "RFC3966", "IDD", "E.164"];

export default defineComponent({
  name: "VPhoneInput",
  extends: VTextField,
  props: {
    defaultCountry: { type: String as PropType<CountryCode>, default: "AU" },
    format: {
      type: String as PropType<NumberFormat>,
      default: "E.164",
      validate: (value: string) =>
        value && formats.includes(value.toUpperCase()),
    },
    modelValue: [String, Object],
    noFlagIcon: { type: Boolean, default: false },
    placeholder: { type: String, default: "(201) 555-0123" },
    returnObject: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  setup(props, context) {
    const errors = ref<string[]>([]);
    const iso = ref(props.defaultCountry);
    const isValid = ref(true);

    const phone = ref<PhoneNumber | undefined>();
    const phoneValue = ref<string | undefined>();

    const setNumber = (value: string) => {
      number.value = value;
    };

    const emitValue = (value: string) => {
      const out = !props.returnObject
        ? value
        : {
            value,
            country: phone.value?.country,
            valid: isValid.value,
            type: phone.value?.getType(),
          };
      context.emit("update:modelValue", out);
    };

    const number = computed({
      set(value: string | undefined) {
        phoneValue.value = value;
        if (value) {
          phone.value = parsePhoneNumber(value, props.defaultCountry);

          if (!phone.value) {
            if (props.required) {
              errors.value =
                value && value.length > 2
                  ? [`${value} is not a valid phone number`]
                  : ["Phone number is required"];
            } else {
              errors.value = [];
            }
            emitValue("");
            return;
          }

          isValid.value = phone.value.isValid();
          iso.value = phone.value.country || props.defaultCountry;

          if (!isValid.value) {
            errors.value = [`${value} is not a valid phone number`];
          } else {
            errors.value = [];
            value = phone.value.format(
              props.format.toUpperCase() as NumberFormat
            );
          }

          emitValue(value);
          context.emit("update:valid", isValid.value);
        } else {
          emitValue("");
        }
      },
      get(): string | undefined {
        if (phoneValue.value !== undefined) {
          return phoneValue.value;
        }
        if (typeof props.modelValue === "object") {
          return get(props.modelValue, "number");
        } else {
          return props.modelValue;
        }
      },
    });

    watch(
      () => props.defaultCountry,
      (value: CountryCode) => {
        if (!number.value && value) {
          iso.value = value;
        }
      }
    );

    return {
      number,
      errors,
      iso,
      setNumber,
      phoneValue,
      phone,
    };
  },
  render() {
    const slots: Record<string, Slot | { (): VNode | VNode[] }> = {};

    // forward props
    Object.entries(this.$slots).forEach(([name, slot]) => {
      if (slot) {
        slots[name] = (props: any) =>
          slot({ ...props, iso: this.iso, phone: this.phone });
      }
    });

    if (!this.noFlagIcon && !slots["prepend-inner"]) {
      const Flag = resolveComponent("Icon");
      slots["prepend-inner"] = () =>
        h(Flag, {
          name: "flagpack:" + String(this.iso).toLowerCase(),
          class: "mt-1 mx-1",
          style: "border-radius: 3px",
        });
    }

    const errorMessages = Array.isArray(this.errorMessages)
      ? this.errorMessages
      : this.errorMessages
      ? [this.errorMessages]
      : [];

    const props: any = omit(this.$props, [
      "defaultCountry",
      "format",
      "modelValue",
      "noFlagIcon",
      "returnObject",
      "required",
    ]);

    return h(
      VTextField,
      {
        ...this.$attrs,
        ...props,
        placeholder: this.placeholder,
        modelValue: this.number,
        "onUpdate:modelValue": this.setNumber,
        errorMessages: [...this.errors, ...errorMessages],
      },
      slots
    );
  },
});
